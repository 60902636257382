import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";

export default function Greeting() {
  return (
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1 className="greeting-text">
              <span className="wave-emoji" role="img" aria-label="wave">
                👋🏼
              </span>{" "}
              {greeting.title}{" "}
            </h1>
            <p className="greeting-text-p subTitle">
              Full stack developer experienced in both front-end using React and
              Angular, and back-end using NodeJs, Python and .Net as well as
              Database technologies like MySQL, PostgreSQL and MongoDB.
            </p>

            <p className="greeting-text-p subTitle">
              Programming has been a passion of mine ever since I was a kid, but
              most notably at 15 when I started learning my first language; Lua.
              I’m a fast learner and always eager to adopt new technologies. I'm
              actively learning and expanding my knowledge when it comes to
              topics I find interesting, such as Cloud Computing and System
              Administration. You can also often find me answering questions on
              Stack Overflow in order to give back to the community.
            </p>
            <SocialMedia />
            <div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
              <Button
                text="See my resume"
                newTab={true}
                href={greeting.resumeLink}
              />
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img
            alt="saad sitting on table"
            src={require("../../assests/images/manOnTable.svg")}
          ></img>
        </div>
      </div>
    </div>
  );
}
