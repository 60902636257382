import React from "react";
import "./SocialMedia.css";
import { socialMediaLinks, contactInfo } from "../../portfolio";

export default function socialMedia() {
  return (
    <div className="social-media-div">
      <a
        href={socialMediaLinks.github}
        className="icon-button github"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-github"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.linkedin}
        className="icon-button linkedin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-linkedin-in"></i>
        <span></span>
      </a>
      <a
        href={`mailto:${socialMediaLinks.gmail}`}
        className="icon-button google"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-google"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.gitlab}
        className="icon-button gitlab"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-gitlab"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.medium}
        className="icon-button medium"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-medium-m"></i>
        <span></span>
      </a>
      <a
        href={socialMediaLinks.stackoverflow}
        className="icon-button stack-overflow"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-stack-overflow"></i>
        <span></span>
      </a>
      <a
        className="icon-button email"
        href={"mailto:" + contactInfo.email_address}
      >
        <i class="fas fa-envelope"></i>
        {/* {contactInfo.email_address} */}
      </a>
    </div>
  );
}
