import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-div">
      <p className="footer-text" role="img" aria-label="footer">
        Forked from{" "}
        <a
          href="https://github.com/saadpasta/developerFolio"
          class="footer-anchor"
        >
          DeveloperFolio
        </a>
      </p>
    </div>
  );
}
