import React from "react";
import "./Header.css";

function Header() {
  return (
    <div>
      <header className="header">
        <a href="" className="logo"></a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <a href="#skills">Skills</a>
          </li>
          {/* <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#opensource">Open Source</a>
          </li> */}
          {/* <li>
            <a href="#blogs">Blogs</a>
          </li> */}
          {/* <li>
            <a href="#talks">Talks</a>
          </li> */}
          <li>
            <a href="#certification">Certification</a>
          </li>

          <li>
            <a href="#timeline">Timeline</a>
          </li>
          <li>
            <a href="#contact">Contact Me</a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/ahmedghanima/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@AhGh"
              target="_blank"
              rel="noopener noreferrer"
            >
              Medium
            </a>
          </li>
          <li>
            <a
              href="https://stackoverflow.com/users/11028815/ghanima"
              target="_blank"
              rel="noopener noreferrer"
            >
              StackOverflow
            </a>
          </li>
          <li>
            <a
              href="https://github.com/AhGhanima"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </li>
        </ul>
      </header>
    </div>
  );
}
export default Header;
