import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "./Timeline.css";
import "react-vertical-timeline-component/style.min.css";
import { FaGraduationCap, FaBaby, FaAws } from "react-icons/fa";
import { MdWork, MdLaptop } from "react-icons/md";

export default function Timeline() {
  return (
    <div>
      <a id="timeline"></a>
      <VerticalTimeline>
      <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{
            borderRight: "7px solid  rgb(33, 150, 243)",
          }}
          date="2021 - present"
          iconStyle={{ background: "#252f3e", color: "#fff" }}
          icon={<MdWork />}
        >
          <h3 className="vertical-timeline-element-title">
            Solutions Architect
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Amazon Web Services</h4>
          <p>Berlin, Germany</p>
        </VerticalTimelineElement>
        
        
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Jan 2021"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<MdWork />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Accointing.com</h4>
          <p>Remote</p>
        </VerticalTimelineElement>
        

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="July 2020"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<MdWork />}
        >
          <h3 className="vertical-timeline-element-title">
            Senior Software Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">ITWORX</h4>
          <p>Cairo, Egypt</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="August 2019"
          iconStyle={{ background: "#252f3e", color: "#fff" }}
          icon={<FaAws />}
        >
          <h3 className="vertical-timeline-element-title">
            AWS Certified Solutions Architect - Associate
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Certificate</h4>
          <p>Amazon Web Services</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="June 2019"
          iconStyle={{ background: "#252f3e", color: "#fff" }}
          icon={<FaAws />}
        >
          <h3 className="vertical-timeline-element-title">
            AWS Certified Developer - Associate
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Certificate</h4>
          <p>Amazon Web Services</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="August 2018"
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={<MdWork />}
        >
          <h3 className="vertical-timeline-element-title">Software Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle">ITWORX</h4>
          <p>Cairo, Egypt</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2014 - 2018"
          iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
          icon={<FaGraduationCap />}
        >
          <h3 className="vertical-timeline-element-title">
            Bachelor of Science in Business Informatics
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor Degree
          </h4>
          <p>German University in Cairo</p>
          <p>Cairo, Egypt</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="July 2017 - August 2017"
          iconStyle={{ background: "#2196f3", color: "#fff" }}
          icon={<MdLaptop />}
        >
          <h3 className="vertical-timeline-element-title">Internship</h3>
          <h4 className="vertical-timeline-element-subtitle">AstraZeneca</h4>
          <p>Cairo, Egypt</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<FaBaby />}
        />
      </VerticalTimeline>
    </div>
  );
}
